<template>
    <div class="container">
        <header class="header">
            <div>
                <h1 class="header--text">Terms and conditions</h1>
            </div>
        </header>
        <div class="sub">
            <button class="back-btn" @click="onClick">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160zm352-160l-160 160c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L269.3 256 406.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z"/>
                </svg>
                <span>Back</span>
            </button>
            <p>Efective: 1 March, 2023</p>
        </div>
        <div class="terms-and-conditions">
            <p>The social media network www.efan.gg is intended for informational and fun purposes only.</p>
            <p>The administrators of this social media platform are not responsible for the Content posted by users. 
                All users are solely responsible for the information they post or share on this platform, including but not limited to text, images, videos, and links.
                The administrators do not review or verify the accuracy, completeness, or reliability of any information users post. 
                The opinions and views expressed by users are their own and do not necessarily reflect those of the administrators.
            </p>
            <p>Users are expected to comply with all applicable laws and regulations, including but not limited to those related to intellectual property, privacy, and data protection. 
                The administrators reserve the right to remove any content that violates these laws or regulations.</p>

            <p>By using this social media platform, you agree to indemnify and hold the administrators harmless from any and all claims, damages, and expenses, including but not limited to legal fees, arising from your use of this platform and your posting or sharing of Content.</p>

            <p>Please be aware that the information and opinions expressed by users on this platform do not represent the views or opinions of the administrators and should not be relied upon for any purpose.</p>

            <p>Efan is committed to fostering an inclusive and respectful community. As such, any form of hate speech or discrimination based on race, religion, gender, sexual orientation, or any other personal characteristic will not be tolerated.</p>

            <p>Users are expected to engage in constructive and respectful conversations, even when they have differences of opinion. 
                Comments that contain hate speech, harassment, or bullying will be removed immediately, and the user may be banned from the platform at the discretion of the administrators.</p>

            <p>The administrators do not endorse or support any form of hate speech or discrimination and will take appropriate action to maintain a safe and inclusive environment for all users.</p>

            <p>If you witness any form of hate speech or discrimination in the comments section of this page, please report it to the administrators immediately so that appropriate action can be taken.</p>

            <p>Efan gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you as part of the Services. 
                This license has the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by Efan in the manner permitted by these Terms.</p>

            <p>The Services are protected by copyright and trademarks. 
                Nothing in the Terms gives you a right to use the Efan name or any of the Efans trademarks, logos, domain names, other distinctive brand features, and other proprietary rights. 
                All right, title and interest in and to the Services (excluding Content provided by users) are and will remain the exclusive property of Efan and its licensors. </p>

            <p>You retain your rights to any Content you submit, post or display on or through the Services. 
                What’s yours is yours — you own your Content (and your incorporated photos and videos are considered part of the Content).</p>

            <p>By submitting, posting or displaying Content on or through the Services, you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such Content in any and all media or distribution methods now known or later developed. 
                This license authorises us to make your Content available to the rest of the world and to let others do the same.</p>

            <p>Who may use the services?
                You may use the Services only if you agree to form a binding contract with Efan. 
                If you are accepting these Terms and using the Services on behalf of a company, organisation, government, or other legal entity, you represent and warrant that you are authorised to do so and have the authority to bind such entity to these Terms.
            </p>
            <p>Community standards.
                www.efan.gg community standards apply to everyone worldwide and for all types of Content. 
            </p>

            <p>Cookies policy.
                Cookies help us provide personalised Content and to improve our service for a safer and faster experience. We do not sell cookie information to any third-party companies. We make the dough, we keep the dough.  
            </p>

            <p>If you have any questions about these terms and conditions, don't hesitate to get in touch with us at - info@efan.gg </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "TermsAndConditions",
    mounted() {
        document.addEventListener('keyup', this.handleEscape);
    },
    destroyed() {
        document.removeEventListener('keyup', this.handleEscape);
    },
    methods: {
        onClick() {
            this.$router.go(-1);
        },
        handleEscape(ev) {
            if(ev.key === 'Escape') {
                this.$router.go(-1);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .container {
        position: relative;
        // @include gradient;
        @include center;
        text-align: left;
        height: 100%;

        display: grid;
        scroll-behavior: smooth;
        background-color: var(--background) !important;

        .header {
            background-color: var(--dark);
            div {
                width: 100%;
                max-width: 800px;
                margin: 0 auto;
            }

            &--text {
                max-width: 800px;
                padding: 0 20px;
                color: var(--text);
            }
        }

        .sub {
            display: flex;
            text-align: center;
            justify-content: center;
            background-color: var(--background);
            color: var(--text);

            .back-btn {
                display: flex;
                align-items: center;
                position: fixed;
                top: 15px;
                left: 10px;
                @include button(10px, false, var(--background));
                @include respond-to("large") {
                    top: auto;
                    bottom: 10px;
                }
    
                & span {
                    margin-left: 5px;
                    font-size: 1rem;
                }
    
                & svg {
                    width: 20px;
                    height: 20px;
                    fill: #fff;
                }
            }
        }


        .terms-and-conditions {
            color: var(--text);
            max-width: 800px;
            margin: 0 auto;

            p {
                font-size: 1.4rem;
                padding: 0 20px;
            }
        }
    }
</style>